import React, { useState, useEffect, Fragment } from 'react'
import SEO from '../components/seo'
import './account.scss'
import {
  getShopifyCustomer,
  getCustomerSubscriptions,
  cancelSubscription,
} from '../helpers/requests'
import { formatMoney, formatDate } from '../helpers'
import Cookies from 'js-cookie'
import AddressForm from '../components/AddressForm/AddressForm'
import { saveAddress } from '../helpers/requests'

const AccountPage = () => {
  const [customer, setCustomer] = useState({})
  const [subscriptions, setSubscriptions] = useState([])
  console.log(subscriptions)
  const [lineItems, setLineItems] = useState([])
  const [refetch, setRefetch] = useState(false)
  const [editingAddress, setEditingAddress] = useState(false)
  useEffect(() => {
    const cartQuantity = document.querySelector('.cart-quantity')
    cartQuantity.classList.remove('black')
  }, [])

  useEffect(() => {
    const accessToken = Cookies.get('KEPT_SESS')
    getShopifyCustomer(accessToken).then(res => {
      console.log(res)
      setCustomer(res.data.data.customer)
    })
  }, [refetch])

  useEffect(() => {
    console.log(customer)
    if (customer.id) {
      getCustomerSubscriptions(customer.id).then(res => {
        console.log(res)
        setSubscriptions(res.data)
      })
    }
  }, [customer, refetch])

  const cancelSubscriptionFn = e => {
    if (e.target) {
      console.log(e.target)
      const { chargeid } = e.target.dataset
      console.log(chargeid)
      cancelSubscription(chargeid).then(res => {
        console.log(res)
        setRefetch('subscription')
      })
    }
  }
  const logOut = () => {
    Cookies.remove('KEPT_SESS')
    window.location.href = '/'
  }
  const saveAddressFn = async (values, setSubmitting) => {
    console.log(Cookies.get('KEPT_SESS'), values)
    const {
      firstName,
      lastName,
      address1,
      address2,
      city,
      provinceCode,
      zip,
      countryCodeV2,
    } = values
    const mailingAddressInput = {
      firstName,
      lastName,
      address1,
      address2,
      city,
      province: provinceCode,
      zip,
      country: countryCodeV2,
    }
    await saveAddress(Cookies.get('KEPT_SESS'), mailingAddressInput)
    setRefetch(true)
    setEditingAddress(false)
  }
  return (
    <div className="pl-60">
      <SEO title="Account" />
      <div className="container text-center">
        <header className="row no-gutters pt-50 main-header">
          <h1 className="ma u-h0">Account</h1>
        </header>
      </div>
      <div className="sign-out-bar">
        <a href="javascript:void(0);" onClick={logOut}>
          Sign Out
        </a>
      </div>
      <div className="account halves">
        <div className="first half">
          <div className="container text-center">
            <div className="row no-gutters account-header">
              <h3 className="ma u-h3">Address</h3>
            </div>
            <div className="addresses container tc">
              {editingAddress ? (
                <AddressForm
                  customer={customer}
                  setEditingAddress={setEditingAddress}
                  setRefetch={setRefetch}
                  saveAddressFn={saveAddressFn}
                  registrationStatus="UPDATING_ADDRESS"
                />
              ) : (
                <Fragment>
                  {customer.defaultAddress ? (
                    <Fragment>
                      <div>
                        {`${customer.defaultAddress.firstName} ${customer.defaultAddress.lastName}`}
                      </div>
                      <div>{customer.defaultAddress.address1}</div>
                      <div>{customer.defaultAddress.address2}</div>
                      <div>{`${customer.defaultAddress.city}, ${
                        customer.defaultAddress.provinceCode
                          ? `${customer.defaultAddress.provinceCode}, `
                          : null
                      }${customer.defaultAddress.countryCodeV2}`}</div>
                    </Fragment>
                  ) : (
                    <div />
                  )}
                  <div className="relative">
                    <button
                      onClick={() => setEditingAddress(true)}
                      className="address-section-btn"
                    >
                      {customer.defaultAddress ? 'Edit' : 'Add'}
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="second half">
          <div className="container text-center">
            <div className="row no-gutters account-header">
              <h3 className="ma u-h3">Subscriptions</h3>
            </div>
          </div>
          <div className="subscriptions">
            {subscriptions.length ? (
              subscriptions.map(sub => {
                return (
                  <div className="subscription">
                    <div className="line-items">
                      {sub.lineItems.map(item => {
                        return (
                          <div className="line-item d-flex flex-between">
                            <input type="hidden" data-variantid={item.var_id} />
                            <h4 className="u-h4">{item.title}</h4>
                            <p>{formatMoney(item.price)}</p>
                          </div>
                        )
                      })}
                    </div>
                    <div className="d-flex align-center flex-between">
                      <div className="info">
                        <div>
                          Last charge date:{' '}
                          {formatDate(sub.last_charge_date, 0)}
                        </div>
                        <div>
                          Next charge date:{' '}
                          {formatDate(sub.last_charge_date, 30)}
                        </div>
                      </div>
                      <div className="relative">
                        <button
                          className="address-section-btn"
                          data-chargeid={sub.charge_id}
                          onClick={cancelSubscriptionFn}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className="container tc" style={{ paddingBottom: '2rem' }}>
                No current subscriptions.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountPage
